import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Flex,
  theme,
  Spacer,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import { DocNav } from "./DocNav"

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box fontFamily={"Open Sans"} textAlign="center" fontSize="xl">
      <VStack spacing={8}>
        <Flex w="100%">
          <Logo h="10vmin" />
          <Spacer />
          <Text fontSize={"xxx-large"}>Dash API Documentation</Text>

          <Spacer />
          <ColorModeSwitcher justifySelf="flex-end" />
        </Flex>
        <DocNav />
      </VStack>
    </Box>
  </ChakraProvider>
)
