import { Button, Card, CardBody, CardFooter, CardHeader, HStack, Icon, Link } from "@chakra-ui/react";
import { FiDollarSign, FiCreditCard, FiUsers, FiUserCheck } from "react-icons/fi";

export const DocNav = () => {
    return <HStack spacing={8}>
        <Card>
            <CardHeader>Authentication</CardHeader>
            <CardBody><Icon h="100px" w="100px" as={FiUserCheck} /></CardBody>
            <CardFooter><Button href="authentication" as={Link}>View Docs</Button></CardFooter>
        </Card>
        <Card>
            <CardHeader>Cards</CardHeader>
            <CardBody><Icon h="100px" w="100px" as={FiCreditCard} /></CardBody>
            <CardFooter><Button href="cards" as={Link}>View Docs</Button></CardFooter>
        </Card>
        <Card>
            <CardHeader>Payments</CardHeader>
            <CardBody><Icon h="100px" w="100px" as={FiDollarSign} /></CardBody>
            <CardFooter><Button href="payments" as={Link}>View Docs</Button></CardFooter>
        </Card>
        <Card>
            <CardHeader>Users</CardHeader>
            <CardBody><Icon h="100px" w="100px" as={FiUsers} /></CardBody>
            <CardFooter><Button href="users" as={Link}>View Docs</Button></CardFooter>
        </Card>
    </HStack>;
}

